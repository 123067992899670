import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import Navigation from "../components/layout/Navigation";
import PageTransition from "../components/layout/PageTransition";
import SEO from "../components/helper/SEO";
import {graphql, Script, StaticQuery} from "gatsby";
import MyPortableText from "../components/helper/MyPortableText";
import "../styles/global.scss";

const Layout = ({children, location, data}) => {

    const staticSettings = data?.allSanitySettings?.edges[0]?.node;
    const [productionPage, setProductionPage] = useState(true);

    useEffect(() => {
        if (location?.hostname !== 'quattrogrammo.com') {
            setProductionPage(false);
        }
        console.log(productionPage);
        console.log(staticSettings?.maintenance);
    }, [location]);

    if (staticSettings?.maintenance.maintenance && productionPage) {
        return (<>
            <SEO title={staticSettings?.maintenance?.maintenance_seo_title} description={staticSettings?.maintenance?.maintenance_seo_description} location={location}></SEO>
            <div className="content base-page min-h-screen">
                <h1 className={"u-flex-center"}>{staticSettings?.maintenance?.maintenancetitle}</h1>
                <div className="mt-5 u-flex-center">
                    <MyPortableText value={staticSettings?.maintenance?._rawMaintenancetext}></MyPortableText>
                </div>
                <div className="u-flex-center">
                    <a className="btn__container" href={staticSettings?.maintenance?.maintenancebutton?.link} target="_blank">
                        <div className="btn">{staticSettings?.maintenance?.maintenancebutton?.title}</div>
                    </a>
                </div>
            </div>
        </>)
    }
    else{
        return (
            <div>
                <Navigation settings={staticSettings} location={location}></Navigation>
                {children}
            </div>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired, data: PropTypes.shape({
        allSanitySettings: PropTypes.shape({
            edges: PropTypes.shape({
                node: PropTypes.shape({
                    maintenance: PropTypes.string.isRequired,
                }),
            }).isRequired,
        }).isRequired,
    }).isRequired,
}


// 👇 Query component
export default function myLayout(props) {
    return (<StaticQuery
        query={graphql`
        query SiteTitleQuery {
          allSanitySettings{
                edges {
                    node {
                        maintenance{
                        maintenance
                         maintenance_seo_description
                        maintenance_seo_title
                        maintenancetitle
                        maintenancebutton {
                            title
                            link
                        }
                        _rawMaintenancetext
                        }
                      
                        address_street
                        address_nr
                        address_plz
                        address_ort
                        sociallinks{
                            value
                            title
                            type
                        }
                        email
                    }
                }
            }
        }
      `}
        render={data => <Layout data={data} {...props} />}
    />);
}
