import React, {useRef, useState} from "react";
import Icon from "../helper/Icon";
import {Link} from "gatsby";
import styled from "styled-components";
import {navigate} from 'gatsby';

const Menu = styled.div`
    display: ${({nav}) => (nav ? "flex" : "none")} !important;
`

const Navigation = ({location, settings}) => {

    const [nav, changeNavState] = useState(false);

    const menuRef = useRef();

    const changeNav = () => {
        if (nav) {
            document.body.classList.remove("stop-scrolling");
            menuRef.current.classList.add('fade-out');

            setTimeout(() => {
                changeNavState(false);
                if (menuRef.current) {
                    menuRef.current.classList.remove('fade-out');
                }
            }, 300)
        } else {
            document.body.classList.add("stop-scrolling");
            changeNavState(true);
        }
    }

    return (
        <div>
            <nav id="nav-container">
                <div className="nav__header blurred">
                    <Link to={"/#home"} className="nav__logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="481.556" height="61.793" viewBox="0 0 481.556 61.793">
                            <g id="Group_20" data-name="Group 20" transform="translate(-6526 469.897)">
                                <path className="svgfill" id="Path_32" data-name="Path 32" d="M19.137,56.451a16.508,16.508,0,0,1-5.821.957C4.7,57.408,0,52.306,0,43.376V14.033C0,5.1,4.7,0,13.316,0S26.631,5.1,26.631,14.033V43.376a15.8,15.8,0,0,1-2.472,9.169c.639,1.116,1.675,1.276,3.27,1.276h1.355v7.973H26.472c-3.269,0-6.3-1.2-7.335-5.342M17.86,43.933V13.475c0-3.986-1.754-5.5-4.545-5.5s-4.545,1.516-4.545,5.5V43.933c0,3.987,1.754,5.5,4.545,5.5s4.545-1.514,4.545-5.5" transform="translate(6526 -469.897)"/>
                                <path className="svgfill" id="Path_33" data-name="Path 33" d="M40.4,42.578V0H49.17V43.216c0,3.986,1.674,5.422,4.465,5.422S58.1,47.2,58.1,43.216V0h8.452V42.578c0,8.93-4.465,14.033-13.076,14.033S40.4,51.507,40.4,42.578" transform="translate(6517.811 -469.897)"/>
                                <path className="svgfill" id="Path_34" data-name="Path 34" d="M86.988,0h11.88l9.09,55.814H99.187L97.593,44.731v.159H87.626L86.031,55.814H77.9Zm9.568,37.316L92.65,9.727h-.16L88.663,37.316Z" transform="translate(6510.209 -469.897)"/>
                                <path className="svgfill" id="Path_35" data-name="Path 35" d="M122.568,7.973H113.4V0h37.77V7.973h-19.83V55.814h-8.77Z" transform="translate(6503.013 -469.897)"/>
                                <path className="svgfill" id="Path_36" data-name="Path 36" d="M214.853,7.973h-19.83V0h37.77V7.973h-9.17V55.814h-8.77Z" transform="translate(6486.467 -469.897)"/>
                                <path className="svgfill" id="Path_37" data-name="Path 37" d="M247.693,0h13c9.089,0,13,4.226,13,12.837v3.428c0,5.741-1.834,9.408-5.741,11v.159c4.385,1.356,5.82,5.5,5.82,11.8v9.807a16.455,16.455,0,0,0,.957,6.778h-8.93c-.478-1.435-.8-2.312-.8-6.858V38.751c0-5.182-1.515-6.858-5.5-6.858h-3.031v23.92h-8.77Zm11.96,23.92c3.269,0,5.262-1.435,5.262-5.9V13.713c0-3.986-1.356-5.741-4.465-5.741h-3.987V23.92Z" transform="translate(6475.791 -469.897)"/>
                                <path className="svgfill" id="Path_38" data-name="Path 38" d="M287.193,43.376V14.033C287.193,5.1,291.9,0,300.509,0s13.316,5.1,13.316,14.033V43.376c0,8.93-4.7,14.032-13.316,14.032s-13.316-5.1-13.316-14.032m17.86.557V13.475c0-3.986-1.754-5.5-4.545-5.5s-4.545,1.516-4.545,5.5V43.933c0,3.987,1.754,5.5,4.545,5.5s4.545-1.514,4.545-5.5" transform="translate(6467.784 -469.897)"/>
                                <path className="svgfill" id="Path_39" data-name="Path 39" d="M343.489,43.375V14.032C343.489,5.1,347.955,0,356.565,0s13.077,5.1,13.077,14.032v4.784h-8.293V13.474c0-3.986-1.754-5.5-4.545-5.5s-4.545,1.515-4.545,5.5V44.013c0,3.986,1.754,5.422,4.545,5.422s4.545-1.435,4.545-5.422V33.089h-4.385V25.116h12.678V43.375c0,8.93-4.466,14.033-13.077,14.033s-13.076-5.1-13.076-14.033" transform="translate(6456.372 -469.897)"/>
                                <path className="svgfill" id="Path_40" data-name="Path 40" d="M383.389,1h13c9.089,0,13,4.226,13,12.837v3.428c0,5.741-1.834,9.408-5.741,11v.159c4.385,1.356,5.821,5.5,5.821,11.8v9.807a16.455,16.455,0,0,0,.956,6.778h-8.93c-.478-1.435-.8-2.312-.8-6.858V39.751c0-5.182-1.515-6.858-5.5-6.858h-3.031v23.92h-8.77Zm11.96,23.92c3.269,0,5.262-1.435,5.262-5.9V14.713c0-3.986-1.355-5.741-4.465-5.741h-3.987V24.92Z" transform="translate(6448.284 -470.099)"/>
                                <path className="svgfill" id="Path_41" data-name="Path 41" d="M429.878,1h11.88l9.09,55.814h-8.771l-1.595-11.082v.159h-9.967l-1.595,10.924h-8.132Zm9.568,37.316L435.54,10.727h-.16l-3.826,27.589Z" transform="translate(6440.703 -470.099)"/>
                                <path className="svgfill" id="Path_42" data-name="Path 42" d="M463.788,1h12.517l5.582,39.947h.159L487.628,1h12.519V56.814h-8.293V14.555h-.16l-6.379,42.259H477.98L471.6,14.555h-.159V56.814h-7.655Z" transform="translate(6431.986 -470.099)"/>
                                <path className="svgfill" id="Path_43" data-name="Path 43" d="M517.588,1H530.1l5.582,39.947h.159L541.427,1h12.519V56.814h-8.293V14.555h-.159l-6.379,42.259H531.78L525.4,14.555h-.159V56.814h-7.655Z" transform="translate(6421.081 -470.099)"/>
                                <path className="svgfill" id="Path_44" data-name="Path 44" d="M570.587,43.376V14.033C570.587,5.1,575.291,0,583.9,0s13.316,5.1,13.316,14.033V43.376c0,8.93-4.7,14.032-13.316,14.032s-13.316-5.1-13.316-14.032m17.86.557V13.475c0-3.986-1.754-5.5-4.545-5.5s-4.545,1.516-4.545,5.5V43.933c0,3.987,1.754,5.5,4.545,5.5s4.545-1.514,4.545-5.5" transform="translate(6410.337 -469.897)"/>
                                <rect className="svgfill" id="Rectangle_28" data-name="Rectangle 28" width="21.321" height="8.432" transform="translate(6643.521 -455.069)"/>
                                <rect className="svgfill" id="Rectangle_29" data-name="Rectangle 29" width="21.321" height="8.432" transform="translate(6670.829 -455.069)"/>
                                <rect className="svgfill" id="Rectangle_30" data-name="Rectangle 30" width="21.321" height="8.432" transform="translate(6643.521 -438.793)"/>
                                <rect className="svgfill" id="Rectangle_31" data-name="Rectangle 31" width="21.321" height="8.432" transform="translate(6670.829 -438.793)"/>
                                <rect className="svgfill" id="Rectangle_32" data-name="Rectangle 32" width="21.321" height="8.432" transform="translate(6643.521 -422.515)"/>
                                <rect className="svgfill" id="Rectangle_33" data-name="Rectangle 33" width="21.321" height="8.432" transform="translate(6670.829 -422.515)"/>
                            </g>
                        </svg>
                    </Link>
                    <div className="nav__links__container">
                        <div className={"hidden tablet:flex"}>
                            <Link to={"/#about"} className="nav__link hover-underline" >Über Uns</Link>
                            <Link className="nav__link hover-underline" to={"/#musik"}>Musik</Link>
                            <Link className="nav__link hover-underline" to={"/#live"}>Live</Link>
                            <Link className="nav__link hover-underline" to={"/#kontakt"}>Kontakt</Link>
                            <div className="nav__link social">
                                <a href="" className="nav__link__social hover-move" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30.946" height="30.941" viewBox="0 0 30.946 30.941">
                                        <g id="Group_22" data-name="Group 22" transform="translate(0 0)">
                                            <path className="svgfill" id="Path_45" data-name="Path 45" d="M30.946,6.95V23.991a1.672,1.672,0,0,0-.063.193c-.1.45-.162.913-.3,1.35a7.731,7.731,0,0,1-7.455,5.4c-1.39,0-2.78,0-4.169,0q-5.559,0-11.118,0a7.208,7.208,0,0,1-3.165-.647A7.579,7.579,0,0,1,.015,23.048c-.03-5.055,0-10.11-.011-15.166A7.793,7.793,0,0,1,6.255.139c.232-.051.467-.093.7-.139H24a.494.494,0,0,0,.133.057,7.247,7.247,0,0,1,2.6.8,7.745,7.745,0,0,1,4.08,5.423c.048.222.088.446.133.67M15.479,9.036a6.434,6.434,0,1,0,6.431,6.43,6.428,6.428,0,0,0-6.431-6.43m10.3-1.95a1.92,1.92,0,1,0-1.906,1.925,1.909,1.909,0,0,0,1.906-1.925"/>
                                        </g>
                                    </svg>
                                </a>
                                <a href="" target="_blank" className="nav__link__social hover-move">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.947" height="27.946" viewBox="0 0 27.947 27.946">
                                        <path className="svgfill" id="Path_14" data-name="Path 14" d="M28.947,4.657V25.289a1.345,1.345,0,0,0-.049.152,4.081,4.081,0,0,1-4.181,3.5q-3.084,0-6.167,0h-.288V19.068H22.4c.274-1.643.545-3.264.82-4.914h-4.95V10.813h4.95v-4.9h-.294q-2.279,0-4.558,0a4.917,4.917,0,0,0-5.027,5.02c0,.973,0,1.946,0,2.92v.307H10.079v4.916h3.244v9.859c-.056,0-.1.01-.142.01q-4.094,0-8.187,0a3.584,3.584,0,0,1-1.581-.366A4.009,4.009,0,0,1,1,24.743Q.991,14.973,1,5.2c0-.091,0-.182,0-.272A3.983,3.983,0,0,1,3.293,1.438,8.993,8.993,0,0,1,4.659,1H25.29a1.087,1.087,0,0,0,.153.049,3.977,3.977,0,0,1,3.118,2.345A9.052,9.052,0,0,1,28.947,4.657Z" transform="translate(-1 -1)"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className={"tablet:hidden"}>
                            <div className={nav ? "burger close" : "burger"} onClick={() => {
                                changeNav();
                            }}>
                                <div className={"line bg-dark"}></div>
                                <div className={"line bg-dark"}></div>
                                <div className={"line bg-dark"}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Menu className={`menu fade-in nav__mobile__container blurred`} nav={nav} ref={menuRef} id="nav-menu">
                    <a className="nav__mobile__link hover-underline" href={"/#about"} onClick={() => {changeNav()}}>Über Uns</a>
                    <a className="nav__mobile__link hover-underline" href={"/#musik"} onClick={() => {changeNav()}}>Musik</a>
                    <a className="nav__mobile__link hover-underline" href={"/#live"} onClick={() => {changeNav()}}>Live</a>
                    <a className="nav__mobile__link hover-underline" href={"/#kontakt"} onClick={() => {changeNav()}}>Kontakt</a>
                    <div className="nav__mobile__link social">
                        <a href="" className="nav__link__social hover-move" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.946" height="30.941" viewBox="0 0 30.946 30.941">
                                <g id="Group_22" data-name="Group 22" transform="translate(0 0)">
                                    <path className="svgfill" id="Path_45" data-name="Path 45" d="M30.946,6.95V23.991a1.672,1.672,0,0,0-.063.193c-.1.45-.162.913-.3,1.35a7.731,7.731,0,0,1-7.455,5.4c-1.39,0-2.78,0-4.169,0q-5.559,0-11.118,0a7.208,7.208,0,0,1-3.165-.647A7.579,7.579,0,0,1,.015,23.048c-.03-5.055,0-10.11-.011-15.166A7.793,7.793,0,0,1,6.255.139c.232-.051.467-.093.7-.139H24a.494.494,0,0,0,.133.057,7.247,7.247,0,0,1,2.6.8,7.745,7.745,0,0,1,4.08,5.423c.048.222.088.446.133.67M15.479,9.036a6.434,6.434,0,1,0,6.431,6.43,6.428,6.428,0,0,0-6.431-6.43m10.3-1.95a1.92,1.92,0,1,0-1.906,1.925,1.909,1.909,0,0,0,1.906-1.925"/>
                                </g>
                            </svg>
                        </a>
                        <a href="" target="_blank" className="nav__link__social hover-move">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27.947" height="27.946" viewBox="0 0 27.947 27.946">
                                <path className="svgfill" id="Path_14" data-name="Path 14" d="M28.947,4.657V25.289a1.345,1.345,0,0,0-.049.152,4.081,4.081,0,0,1-4.181,3.5q-3.084,0-6.167,0h-.288V19.068H22.4c.274-1.643.545-3.264.82-4.914h-4.95V10.813h4.95v-4.9h-.294q-2.279,0-4.558,0a4.917,4.917,0,0,0-5.027,5.02c0,.973,0,1.946,0,2.92v.307H10.079v4.916h3.244v9.859c-.056,0-.1.01-.142.01q-4.094,0-8.187,0a3.584,3.584,0,0,1-1.581-.366A4.009,4.009,0,0,1,1,24.743Q.991,14.973,1,5.2c0-.091,0-.182,0-.272A3.983,3.983,0,0,1,3.293,1.438,8.993,8.993,0,0,1,4.659,1H25.29a1.087,1.087,0,0,0,.153.049,3.977,3.977,0,0,1,3.118,2.345A9.052,9.052,0,0,1,28.947,4.657Z" transform="translate(-1 -1)"/>
                            </svg>
                        </a>
                    </div>
                </Menu>
            </nav>
        </div>
    )
}
export default Navigation;
